
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD11t55-5FTb8aufddZ720Chth6mb-lKl0",
    authDomain: "darlibaba-10ad0.firebaseapp.com",
    projectId: "darlibaba-10ad0",
    storageBucket: "darlibaba-10ad0.appspot.com",
    messagingSenderId: "282825899082",
    appId: "1:282825899082:web:89f6d6fbb34719ee777ab3",
    measurementId: "G-LBLJF0V7W1"
  };


const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
auth.languageCode = "en";

const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                console.log('Recaptcha resolved');
            },
            'expired-callback': () => {
                console.log('Recaptcha expired');
            }
        }, auth);
        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });
    }
};

export { auth, db, storage, setUpRecaptcha, signInWithPhoneNumber };