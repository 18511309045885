import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom'; // useHistory if you're using React Router v5
import TypingEffect from '../common/TypingEffect';
import { Link } from "react-router-dom";
import '../css/inquiry.css';
import '../css/common.css'

function OemInquiry({ isLoggedIn }) {
    const inputRefs = useRef([]);

    const [page, setPage] = useState(0);
    const [reset, setReset] = useState(false);
    const navigate = useNavigate(); // useHistory() if using React Router v5

    const [name, setName] = useState('');
    const [product, setProduct] = useState('');
    const [purpose, setPurpose] = useState('');
    const [qty, setQty] = useState('');
    const [price, setPrice] = useState('');

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/oeminquiry'); // history.push('/oeminquiry') if using React Router v5
        }
    }, [isLoggedIn, navigate]); // or [isLoggedIn, history] for React Router v5


        // 수량이 숫자가 아닌 경우 제거
        const handleQtyChange = (event) => {

          const qtyValue = event.target.value;
  
          const filteredQty = qtyValue.replace(/[^0-9]/g, '');
  
          setQty(filteredQty);
      };

          // 예산이 숫자가 아닌 경우 제거
    const handlePriceChange = (event) => {

      const priceValue = event.target.value;

      const filteredPrice = priceValue.replace(/[^0-9]/g, '');

      setPrice(filteredPrice);
  };

      //값에 콤마
      const valueComma = (feeNum) => {
        return feeNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const Pages = () => {
        if (page === 0) {
            return (
              <div className="register-form">
              <TypingEffect text={"Hello! Welcome to Darlibaba, your trusted partner for cosmetics OEM production. May I kindly ask for your name?"} typeSpeed={45} reset={reset} />
              <input 
                  spellcheck="false"
                  ref={(el) => inputRefs.current[0] = el}
                  value={name} 
                  onChange={(event) => setName(event.target.value)}
                  onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                          setPage(1);
                          setReset(reset => !reset);
                      }
                  }} 
              />
              </div>
            );
        } else if (page === 1) {
          return (
              <div className="register-form">
                          <TypingEffect text={`${name}, welcome! What type of cosmetic product are you looking to create?`} typeSpeed={45} reset={reset} />
                          <input
                              spellcheck="false"
                              ref={(el) => inputRefs.current[1] = el}
                              value={product} 
                              placeholder="ex) skincare, serum.."
                              onChange={(event) => setProduct(event.target.value)}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(2);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
        } else if (page === 2) {
          return (
              <div className="register-form">
                  <TypingEffect text={`${product}, great choice! What is the intended use of the cosmetic product?`} typeSpeed={45} reset={reset} />
                          <input
                              spellcheck="false"
                              ref={(el) => inputRefs.current[2] = el}
                              value={purpose}
                              placeholder="ex) for sale, for gifting.."
                              onChange={(event) => setPurpose(event.target.value)}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(3);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
      } else if (page === 3) {
          return (
              <div className="register-form">
                  <TypingEffect text={`${purpose}, Understood! How many units are you considering?`} typeSpeed={45} reset={reset} />
                          <input
                              pattern="\d+"
                              ref={(el) => inputRefs.current[3] = el}
                              value={qty}
                              onChange={handleQtyChange}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(4);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
      } else if (page === 4) {
          return (
              <div className="register-form">
                  <TypingEffect text={`${valueComma(qty)} units, got it! What budget do you have in mind?`} typeSpeed={45} reset={reset} />
                          <input
                              ref={(el) => inputRefs.current[4] = el}
                              value={price}
                              onChange={handlePriceChange}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(5);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
      }
      
    }

    return (
        <section className="register">
            <div>{Pages()}</div>
        </section>
    );
}

export default OemInquiry;