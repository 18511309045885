import React from 'react';
import { Parallax } from "react-parallax";
import '../css/common.css'
import Video from './mainContent/Video';
import Intro from './mainContent/Intro';
import OemList from './mainContent/OemList';
import BestSeller from './mainContent/BestSeller';
import Partner from './mainContent/Partner';


function Main({isLoggedIn}) {
  return (
    <>
    <Parallax>
    <Video isLoggedIn={isLoggedIn}/>
    </Parallax>

    <Intro/>

    <OemList />

    <BestSeller />

    <Partner />

   </>
  );
}

export default Main;