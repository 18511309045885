import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // useHistory if you're using React Router v5
import TypingEffect from '../common/TypingEffect';
import { Link } from "react-router-dom";
import '../css/inquiry.css';
import '../css/common.css'

function OemInquirys({ isLoggedIn }) {
    const [page, setPage] = useState(0);
    const [reset, setReset] = useState(false);
    const navigate = useNavigate(); // useHistory() if using React Router v5

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/oeminquiry'); // history.push('/oeminquiry') if using React Router v5
        }
    }, [isLoggedIn, navigate]); // or [isLoggedIn, history] for React Router v5

    const Pages = () => {
        if (page === 0) {
            return (
                <div className="register-form">
                    <TypingEffect 
                        text={"Welcome! This service requires you to log in. Please log in and come back to continue. We look forward to seeing you again!"} 
                        typeSpeed={45} 
                        reset={reset} 
                    />
                    <Link to="/"><button>BACK</button></Link>
                </div>
            );
        }
    }

    return (
        <section className="register">
            <div>{Pages()}</div>
        </section>
    );
}

export default OemInquirys;