import React, { useState, useEffect } from 'react';
import '../css/inquiry.css';

function TypingEffect({ text, typeSpeed, reset }) {
    const [displayedText, setDisplayedText] = useState('');
    const [posText, setPosText] = useState(0);

    useEffect(() => {
        setDisplayedText('');
        setPosText(0);
    }, [reset]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayedText((prev) => prev + text[posText]);
            setPosText((prev) => prev + 1);
        }, typeSpeed);

        if (posText >= text.length) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [posText, text, typeSpeed]);

    return (
        <>
            <span className="containerText">{displayedText}</span>
            <span className="myBlinker"></span>
        </>
    );
}

export default TypingEffect;