import React from 'react';
import '../../css/bestseller.css';

function BestSeller() {
    return (
        <section>
            <div className='main3'>
                <div className='main3-inner'>
                    <div className='main3-heading'>
                        <h1>FEATURED PRODUCTS</h1>
                    </div>
                    <div className='main3-grid-container'>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/upload/NNEditor/20230821/EC8B9CECB9B4_ED8FACED86A0EC83B5.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/e24974a3cf3472d71a5378f8a80625e6.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/215c1c9484b2e32eff9c7476377c637a.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/bdc68e88787d188e7e12fbdfcdba593d.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/a5def968244eecfd1ddabc1ea82fa024.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/1fc0c796313fe6f9b732a1cf453bd6e4.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/07d10e09e1ad627f6f8c356332dc56d6.jpg'/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src='https://cafe24.poxo.com/ec01/bigpie1688/p1p7L96QYgpvk7KwZPVnP+VaUvCSXod98mQpGc9RRfyVYMj/NA/292VDu7B94KqrUgwOUAhar4ZgsSlvFII+Ig==/_/web/product/big/202309/f0d5d0f143b711c74117022857a3abe5.jpg'/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BestSeller;