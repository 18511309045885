import React from 'react';
import '../../css/partner.css';

function Partner() {
    return (
        <section className='partner'>
            <div className='main4'>
                <div className='main4-heading'>
                        <h1>OUR PARTNERS</h1>
                </div>
                <div className="logo_sliderA">
                    <div className="logo_slide_track">
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/ZvD4jI_v6vtjtdh3PNqqE67PGRone7rwKMKaUNEq_cisiKY0d8m9um3gLEd2sSpf7MSJF6Y7Z6KHlgY-1iwjlw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://img.cjnews.cj.net/wp-content/uploads/2020/11/CJ%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%83%88-BI-%EB%A1%9C%EA%B3%A0.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cdn.thebk.co.kr/news/photo/201412/115514_19510_650.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/C229R/btqxpzJDXuF/kqw62SeBzYA0UkQ0rbchD1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/CHlMw/btqxomYv6T4/JvAvxGQx9qoooOTk6T1Mk1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/zoFUG/btqxhyrRYKy/UXmpubpxrivvbykDyOzHN1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://www.heybabescosmetics.com/upload/resize_cache/webp/upload/iblock/d96/catedbmmzbbykkpzeav3nx1oeogycxoc/Dizayn-bez-nazvaniya-_-2023_05_18T151914.467.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cliocosmetic.com/ko/img/main/logo_clio.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG_KQSFLsb9-dzZzsaqrmHZoGmSwo26HVrFQ&s" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://www.cosmorning.com/data/photos/20191147/art_1574065233966_5e9542.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/U7zFF8ygMbAyg8921q2H9ADIPm_BwFoGeSGRupeees2Wdzq1NS9qgrVTPwpOA2tT69ceV6kDyYAQ_YSQ8evvgw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cliocosmetic.com/ko/img/main/logo_peripera.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/ZvD4jI_v6vtjtdh3PNqqE67PGRone7rwKMKaUNEq_cisiKY0d8m9um3gLEd2sSpf7MSJF6Y7Z6KHlgY-1iwjlw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://img.cjnews.cj.net/wp-content/uploads/2020/11/CJ%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%83%88-BI-%EB%A1%9C%EA%B3%A0.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cdn.thebk.co.kr/news/photo/201412/115514_19510_650.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/C229R/btqxpzJDXuF/kqw62SeBzYA0UkQ0rbchD1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/CHlMw/btqxomYv6T4/JvAvxGQx9qoooOTk6T1Mk1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/zoFUG/btqxhyrRYKy/UXmpubpxrivvbykDyOzHN1/img.jpg" alt=""/>
                    </div>
                    </div>
                </div>

                <div className="logo_sliderB">
                    <div className="logo_slide_track">
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/ZvD4jI_v6vtjtdh3PNqqE67PGRone7rwKMKaUNEq_cisiKY0d8m9um3gLEd2sSpf7MSJF6Y7Z6KHlgY-1iwjlw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://img.cjnews.cj.net/wp-content/uploads/2020/11/CJ%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%83%88-BI-%EB%A1%9C%EA%B3%A0.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cdn.thebk.co.kr/news/photo/201412/115514_19510_650.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/C229R/btqxpzJDXuF/kqw62SeBzYA0UkQ0rbchD1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/CHlMw/btqxomYv6T4/JvAvxGQx9qoooOTk6T1Mk1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/zoFUG/btqxhyrRYKy/UXmpubpxrivvbykDyOzHN1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://www.heybabescosmetics.com/upload/resize_cache/webp/upload/iblock/d96/catedbmmzbbykkpzeav3nx1oeogycxoc/Dizayn-bez-nazvaniya-_-2023_05_18T151914.467.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cliocosmetic.com/ko/img/main/logo_clio.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG_KQSFLsb9-dzZzsaqrmHZoGmSwo26HVrFQ&s" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://www.cosmorning.com/data/photos/20191147/art_1574065233966_5e9542.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/U7zFF8ygMbAyg8921q2H9ADIPm_BwFoGeSGRupeees2Wdzq1NS9qgrVTPwpOA2tT69ceV6kDyYAQ_YSQ8evvgw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cliocosmetic.com/ko/img/main/logo_peripera.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/ZvD4jI_v6vtjtdh3PNqqE67PGRone7rwKMKaUNEq_cisiKY0d8m9um3gLEd2sSpf7MSJF6Y7Z6KHlgY-1iwjlw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://img.cjnews.cj.net/wp-content/uploads/2020/11/CJ%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%83%88-BI-%EB%A1%9C%EA%B3%A0.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cdn.thebk.co.kr/news/photo/201412/115514_19510_650.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/C229R/btqxpzJDXuF/kqw62SeBzYA0UkQ0rbchD1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/CHlMw/btqxomYv6T4/JvAvxGQx9qoooOTk6T1Mk1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/zoFUG/btqxhyrRYKy/UXmpubpxrivvbykDyOzHN1/img.jpg" alt=""/>
                    </div>
                    </div>
                </div>

                <div className="logo_sliderC">
                    <div className="logo_slide_track">
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/ZvD4jI_v6vtjtdh3PNqqE67PGRone7rwKMKaUNEq_cisiKY0d8m9um3gLEd2sSpf7MSJF6Y7Z6KHlgY-1iwjlw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://img.cjnews.cj.net/wp-content/uploads/2020/11/CJ%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%83%88-BI-%EB%A1%9C%EA%B3%A0.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cdn.thebk.co.kr/news/photo/201412/115514_19510_650.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/C229R/btqxpzJDXuF/kqw62SeBzYA0UkQ0rbchD1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/CHlMw/btqxomYv6T4/JvAvxGQx9qoooOTk6T1Mk1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/zoFUG/btqxhyrRYKy/UXmpubpxrivvbykDyOzHN1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://www.heybabescosmetics.com/upload/resize_cache/webp/upload/iblock/d96/catedbmmzbbykkpzeav3nx1oeogycxoc/Dizayn-bez-nazvaniya-_-2023_05_18T151914.467.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cliocosmetic.com/ko/img/main/logo_clio.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG_KQSFLsb9-dzZzsaqrmHZoGmSwo26HVrFQ&s" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://www.cosmorning.com/data/photos/20191147/art_1574065233966_5e9542.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/U7zFF8ygMbAyg8921q2H9ADIPm_BwFoGeSGRupeees2Wdzq1NS9qgrVTPwpOA2tT69ceV6kDyYAQ_YSQ8evvgw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cliocosmetic.com/ko/img/main/logo_peripera.png" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://i.namu.wiki/i/ZvD4jI_v6vtjtdh3PNqqE67PGRone7rwKMKaUNEq_cisiKY0d8m9um3gLEd2sSpf7MSJF6Y7Z6KHlgY-1iwjlw.webp" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://img.cjnews.cj.net/wp-content/uploads/2020/11/CJ%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%98%AC%EB%A6%AC%EB%B8%8C%EC%98%81-%EC%83%88-BI-%EB%A1%9C%EA%B3%A0.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://cdn.thebk.co.kr/news/photo/201412/115514_19510_650.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/C229R/btqxpzJDXuF/kqw62SeBzYA0UkQ0rbchD1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/CHlMw/btqxomYv6T4/JvAvxGQx9qoooOTk6T1Mk1/img.jpg" alt=""/>
                    </div>
                    <div className="logo_slide">
                        <img src="https://blog.kakaocdn.net/dn/zoFUG/btqxhyrRYKy/UXmpubpxrivvbykDyOzHN1/img.jpg" alt=""/>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partner;