import React from 'react';
import '../css/common.css'


function Portfolio() {
  return (
    <>

<div style={{border:"1px solid black", display:"flex", width:"100%", height:"260px", background:"grey", paddingTop:"40px"}}>
    <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold"}} >PORTFOLIO</div>
</div>
<div style={{border:"1px solid black", height:"100vh", display:"flex"}}>
        <div style={{border:"1px solid black", width:"1400px", minWidth:"1400px", margin:"auto", height:"100%"}}></div>
    </div>
    
   </>
  );
}

export default Portfolio;